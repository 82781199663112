import React, { useState } from 'react';
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import { useTelegram } from "../../hooks/useTelegram";
import { useNavigate } from "react-router-dom";
import { PATH_REPORT } from '../../constants/Paths';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramInput from "../../components/kit/Input/TelegramInput";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import TelegramSelect from '../../components/kit/Select/TelegramSelect';


const Main = () => {
    const { user } = useTelegram();
    const navigate = useNavigate();
    const [walletAddress, setWalletAddress] = useState('');
    const [network, setNetwork] = useState('Ethereum');
    const [isScanning, setIsScanning] = useState(false);
    const [scanResult, setScanResult] = useState(null);

    const handleScan = async () => {
        setIsScanning(true);
        try {
            const result = await fetchAssets(walletAddress, network);
            console.log(result);
            setScanResult(result); 
        } catch (error) {
            console.error("Error scanning wallet:", error);
        } finally {
            setIsScanning(false);
        }
    };

    const handleReportIssue = () => {
        // Navigate to the report issue page
        navigate(PATH_REPORT);
    };

    return (
        <TelegramScreen showbackbutton={false}>
            <TelegramHeader>
                <TelegramText className="telegramTitle">Portfolio Safeguard</TelegramText>
            </TelegramHeader>

            <TelegramText>Welcome, {user?.username}!</TelegramText>

            <TelegramText className="telegramSubtitle">
                Enter your wallet address and select a network to scan:
            </TelegramText>

            <TelegramInput
                placeholder="Wallet Address"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
            />

            <TelegramSelect
                value={network}
                onChange={(e) => setNetwork(e.target.value)}
                className="telegramSelect"
                elements={["Ethereum", "Binance Smart Chain", "Polygon", "Arbitrum", "Optimism"]}
            />

            <TelegramButton onClick={handleScan} disabled={!walletAddress || isScanning}>
                {isScanning ? "Scanning..." : "Scan Portfolio"}
            </TelegramButton>

            {scanResult && (
                <div className="scan-result">
                    <TelegramText className="telegramSubtitle">Scan Results:</TelegramText>
                    {scanResult.length > 0 ? (
                        <div className="token-results-container">
                            {scanResult.map((token, index) => (
                                <div key={index} className="token-card">
                                    <div className="token-logo">
                                        <img src={token.logos[0]?.uri} alt={token.symbol} width={40} height={40} />
                                    </div>
                                    <div className="token-info">
                                        <TelegramText><strong>{token.name}</strong> ({token.symbol})</TelegramText>
                                        <TelegramText>USD Price: ${token.current_usd_price}</TelegramText>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <TelegramText>No assets found</TelegramText>
                    )}
                    <TelegramButton onClick={handleReportIssue}>
                        Perform Recovery Actions
                    </TelegramButton>
                </div>
            )}

        </TelegramScreen>
    );
};

export default Main;

// Function to fetch assets
async function fetchAssets(walletAddress, network) {
    const chainIdMapping = {
        'Ethereum': 1,
        'Binance Smart Chain': 56,
        'Polygon': 137,
        'Arbitrum': 42161,
        'Optimism': 10
    };

    const chainId = chainIdMapping[network];
    const url = `https://api.chainbase.online/v1/account/tokens?chain_id=${chainId}&address=${walletAddress}`;
    const headers = {
        'x-api-key': '2oULkFlMM3CT7wcXR0fnrDEYotV',  // Replace with your API key
        'accept': 'application/json'
    };

    try {
        const response = await fetch(url, { headers });
        if (!response.ok) {
            throw new Error('Failed to fetch assets');
        }
        const data = await response.json();

        // Filter tokens with current_usd_price > 0
        const filteredTokens = data.data.filter(token => token.current_usd_price > 0);

        return filteredTokens;  // Return the filtered tokens
    } catch (error) {
        console.error("Error fetching assets:", error);
        throw error;
    }
}
